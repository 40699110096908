﻿<script lang="ts">
    import tooltip from 'Lib/Utils/Tooltip';

    export let tooltipContent: string | undefined;
    export let disabled = false;
</script>

<button on:click {disabled} use:tooltip={tooltipContent}>
    <slot/>
</button>

<style>
    button {
        margin: 0;
        padding: .1rem;
        border: none;
        cursor: pointer;
        width: fit-content;
        background-color: unset;
        transition: filter .2s ease-out;
    }

    button:hover {
        filter: brightness(125%);
    }

    button:active {
        filter: brightness(75%);
    }

    button:disabled {
        filter: saturate(0%) brightness(125%);
        cursor: unset;
    }
</style>
