﻿import type {ScenarioFiles} from 'Generated/RestClient.g';

const dateTimeFormatter = new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'short',
    timeStyle: 'short',
});

export function dateFormatter(d?: Date): string {
    try {
        return d ? dateTimeFormatter.format(d) : '-';
    } catch (e) {
        return 'invalid';
    }
}

export function fileDetailsFormatter(data?: ScenarioFiles): string[] {
    const files = data as ScenarioFiles;
    return files.presentationFiles!.concat(files?.dataFiles ?? []);
}
