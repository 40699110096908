﻿<script lang="ts">
    import {applicationContextKey} from 'PublicationDependencies';

    import {type ComponentType, getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import PresentationListView from 'Pages/PublicationPage/Views/PresentationList/PresentationListView.svelte';
    import UserContext from 'Lib/UserContext';
    import ViewSelector from 'Pages/PublicationPage/Components/ViewSelector.svelte';
    import CustomLinkListView from 'Pages/PublicationPage/Views/CustomLinks/CustomLinkListView.svelte';
    import {Column} from 'Components/UI';
    import translations from 'Assets/i18n';

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const userContext = applicationContext.get(UserContext);
    const currentUserObservable = userContext.currentUserObservable;
    $: !$currentUserObservable && location.reload();
    const views: { label: string, value: ComponentType }[] = [
        {label: translations.pages.publication.labels.presentationListViewToggle, value: PresentationListView},
        {label: translations.pages.publication.labels.customLinksViewToggle, value: CustomLinkListView}
    ];
    let value: ComponentType;
</script>
<div>
<ViewSelector bind:selected={value} options={views}/>
<svelte:component this={value}/>
</div>

<style>
    div {
        padding-top: .1rem;
        padding-bottom: .2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 1rem;
    }
</style>
