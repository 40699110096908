﻿<script lang="ts">
    import clickOutside from 'Lib/Utils/ClickOutside';

    export let visible: boolean = false;
    export let onClose: (() => void) | undefined = undefined;
    
    function close(){
        visible=false;
        onClose?.();
    }
</script>
<div class="root" use:clickOutside={close}>
    <div class="menu">
        <slot/>
    </div>
</div>
<style>
    .root {
        position: absolute;
    }

    .menu {
        z-index: 10;
        display: flex;
        position: relative;
        background-color: white;
        transform: translate(-25%);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    }

    .menu::before {
        content: "";
        position: absolute;
        left: calc(50%);
        top: -6px;
        border: 5px solid white;
        transform-origin: 0 0;
        transform: rotate(45deg);
    }

</style>
