﻿<script lang="ts">
    import {Button, Modal, Row, Text} from 'Components/UI';
    import translations from 'Assets/i18n';
    import {applicationContextKey} from 'PublicationDependencies';
    import PublicationListService from 'Pages/PublicationPage/Lib/Services/PublicationListService';
    import * as Style from 'Components/Style';
    import type {Nullable} from 'Lib/Utils/Nullable';
    import {getContext} from 'svelte';
    import type DependencyContainer from 'Lib/DependencyContainer';
    import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';
    import type {ScenarioWithFiles} from 'Generated/RestClient.g';
    import {Column, TextField} from 'Components/UI';

    const translationContext = translations.pages.publication.passwordChangeDialog;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const publicationListService = applicationContext.get(PublicationListService);
    const publicationListContext = applicationContext.get(PublicationListContext);

    export let scenarioId: Nullable<string> = undefined;
    let password: Nullable<string> = undefined;
    let oldPassword: Nullable<string> = undefined;
    let passwordError: Nullable<string> = undefined;
    $: scenarioId && getCurrentPassword();

    async function changePassword(): Promise<boolean> {
        if (!scenarioId) return;

        const newPasswordIsDefined = !!password;
        const oldPasswordIsDefined = !!oldPassword;

        if (newPasswordIsDefined) {
            password = password?.trim();
            if (password?.length == 0) {
                passwordError = translations.pages.publication.invalidPassword;
                return;
            }
        }

        await publicationListService.setPublicationPassword(scenarioId, newPasswordIsDefined ? password : undefined);

        // detects password protection state change
        if (newPasswordIsDefined != oldPasswordIsDefined) {
            (publicationListContext.scenarioList.find(i => i.id == scenarioId) as ScenarioWithFiles).passwordProtected = newPasswordIsDefined;
            publicationListContext.scenarioList = publicationListContext.scenarioList;
        }
        close();
    }

    async function getCurrentPassword() {
        if (!scenarioId) return;

        oldPassword = (await publicationListService.getPublicationPassword(scenarioId)).password;
        password = oldPassword;
    }

    function close() {
        password = undefined;
        oldPassword = undefined;
        scenarioId = undefined;
    }
    
    function clearError(){
        passwordError = undefined;
    }
</script>

<Modal
        isShown={scenarioId !== undefined}
        title={translationContext.title}
        canBeClosed
        closeCallback={close}
>
    <svelte:fragment slot="content">
        <Column width="300px" gap="20px">
            <p>{translationContext.description}</p>
            {#if passwordError}
                <Text color={Style.colors.negative}>{passwordError}</Text>
            {/if}
            <TextField on:input={clearError} bind:value={password} placeholder={translationContext.placeholder}/>
        </Column>
    </svelte:fragment>
    <svelte:fragment slot="buttons">
        <Row horizontalAlign="end">
            <Button
                    minWidth={Style.unset}
                    on:click={close}>
                {translationContext.cancelAction}
            </Button>
            <Button
                    backgroundColor={Style.colors.primary}
                    minWidth={Style.unset}
                    on:click={changePassword}>
                {translationContext.confirmAction}
            </Button>
        </Row>
    </svelte:fragment>
</Modal>
