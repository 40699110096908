<script lang="ts">
    import {applicationContextKey} from 'PublicationDependencies';
    import PresentationService from 'Pages/PresentationPage/Lib/Services/PresentationService';
    import {Button, Row} from 'Components/UI';
    import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
    import UserMenuItem from 'Menubars/Components/UserMenuItem.svelte';
    import translations from 'Assets/i18n';
    import {getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import NotificationService from 'Lib/Services/NotificationService';
    import EnvironmentUtils from 'Lib/Utils/EnvironmentUtils';
    import SaveAsNewDialog from 'Pages/PresentationPage/SaveAsNewDialog.svelte';

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    const presentationService = applicationContext.get(PresentationService);
    const {presentationWritableObservable, currentScenarioPublicDataObservable} = applicationContext.get(PresentationContext);
    const notificationService = applicationContext.get(NotificationService);

    function onSave(): void {
        presentationService.saveAsync();
    }

    function onExportToPdf(): void {
        presentationService.exportToPdf();
    }

    function onFullscreen(): void {
        try {
            if (!document.fullscreenElement)
                document.body.requestFullscreen({navigationUI: 'hide'});
            else
                document.exitFullscreen();
        } catch (e) {
            notificationService.error(translations.global.errors.fullscreenNotSupported);
        }
    }

    let showSaveAsNewDialog = false;
    const fullscreenSupported = !EnvironmentUtils.isInIOSFirefox();
</script>

<div>
    <Row verticalAlign="center">
        {#if $presentationWritableObservable}
            <Button
                    minWidth="32px"
                    padding="0"
                    tooltipContent={translations.global.actions.exportToPdf}
                    on:click={onExportToPdf}>
                <span class="mdi mdi-file-export-outline"/>
            </Button>
        {/if}
        {#if $currentScenarioPublicDataObservable?.cloneable}
            <Button
                    minWidth="32px"
                    padding="0"
                    tooltipContent={translations.global.actions.saveAsNew}
                    on:click={() => showSaveAsNewDialog = true}>
                <span class="mdi mdi-content-save-all-outline"/>
            </Button>
        {/if}
        {#if $presentationWritableObservable}
            <Button
                    minWidth="32px"
                    padding="0"
                    tooltipContent={translations.global.actions.save}
                    on:click={onSave}>
                <span class="mdi mdi-content-save-outline"/>
            </Button>
        {/if}
        {#if fullscreenSupported}
            <Button
                    minWidth="32px"
                    padding="0"
                    tooltipContent={translations.global.actions.fullscreen}
                    on:click={onFullscreen}>
                <span class="mdi mdi-fullscreen"/>
            </Button>
        {/if}
        <UserMenuItem/>
    </Row>
</div>

<SaveAsNewDialog bind:visible={showSaveAsNewDialog} />


<style>
    .mdi {
        font-size: 32px;
    }
</style>
