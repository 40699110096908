﻿<script lang="ts">
    import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
    import type ColumnOptions from 'Pages/PublicationPage/Views/ColumnOptions';
    import type {ComponentType} from 'svelte';

    export let columns: Map<ColumnKey, ColumnOptions>;
    export let buttons: ComponentType;
</script>

<tr>
    {#each columns as [key, options]}
        <td class:primary={options.primary} style="{options.width ? `width:${options.width};` : ''}">
            {#if options.name}
                <span class="label">{options.name}</span>
            {/if}
            {#if key === 'buttons'}
                <svelte:component this={buttons} disabled/>
            {:else}
                <p class="skeleton-loader"/>
            {/if}
        </td>
    {/each}
</tr>

<style>
    .skeleton-loader {
        min-height: 25px;
        background: #eee;
        background: linear-gradient(110deg, #cecece 8%, #f5f5f5 18%, #cecece 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }

    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }
</style>
