<script lang="ts">
    import {Button, Column, EmailField, Modal, Row, Text, TextField} from 'Components/UI';
    import translations from 'Assets/i18n';
    import * as Style from 'Components/Style';
    import {getContext} from 'svelte';
    import {applicationContextKey} from 'PublicationDependencies';
    import DependencyContainer from 'Lib/DependencyContainer';
    import PresentationService from 'Pages/PresentationPage/Lib/Services/PresentationService';
    import NotificationService from 'Lib/Services/NotificationService';

    const translationContext = translations.pages.presentation.saveAsNewDialog;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    const presentationService = applicationContext.get(PresentationService);
    const notificationService = applicationContext.get(NotificationService);
    
    export let visible = false;
    
    function onRequesterInfoChanged(e: InputEvent) {
        const input = e.target as HTMLInputElement;
        isRequesterInfoValid = requester.trim().length > 0 && input.validity.valid;
    }

    async function onSubmit() {
        if(!isRequesterInfoValid) {
            return;
        }
        
        isSaveInProgress = true;
        hadSaveError = false;
        const newScenarioId = await presentationService.saveAsNew(requester);
        isSaveInProgress = false;
        
        if(newScenarioId !== undefined) {
            savedPresentationUrl = `${document.location.href.split('#')[0]}#/${newScenarioId}`;
        }else{
            hadSaveError = true;
        }
    }
    
    function onClose() {
        visible = false;
        isRequesterInfoValid = false;
        isSaveInProgress = false;
        savedPresentationUrl = undefined;
        requester = '';
    }
    
    async function copyToClipboard() {
        presentationUrlInput.select();
        await navigator.clipboard.writeText(savedPresentationUrl);
        notificationService.info(translationContext.publicationURICopied);
    }
    
    let isRequesterInfoValid = false;
    let isSaveInProgress = false;
    let hadSaveError = false;
    let savedPresentationUrl: string|undefined = undefined;
    let presentationUrlInput: HTMLInputElement;
    let requester: string = '';
</script>
<Modal
        canBeClosed={!isSaveInProgress}
        closeCallback={onClose}
        bind:isShown={visible}
        title={translationContext.title}
        showLoading={isSaveInProgress}>
    <svelte:fragment slot="content">
        {#if savedPresentationUrl !== undefined}
            <Column gap="20px">
                <Text>{translationContext.saveSuccessfulDescription}</Text>
                <Row>
                    <div class="pointer" on:click={copyToClipboard}><TextField bind:inputElement={presentationUrlInput} isReadonly={true} value={savedPresentationUrl} /></div>
                    <Button minWidth="fit-content" backgroundColor="transparent" textColor={Style.textColors.default} on:click={copyToClipboard}>
                        <span class="mdi mdi-content-copy"></span>
                    </Button>
                </Row>
            </Column>
        {:else}
            {#if hadSaveError}
                <Text color={Style.colors.negative}>{translationContext.saveFailed}</Text>
            {/if}
            <form on:submit|preventDefault={onSubmit}>
                <EmailField on:submit={onSubmit} bind:value={requester} label={translationContext.email} placeholder={translationContext.placeholder} on:input={onRequesterInfoChanged}/>
            </form>
        {/if}
    </svelte:fragment>
    <svelte:fragment slot="buttons">
        <Row horizontalAlign="end">
            {#if savedPresentationUrl !== undefined}
                <Button backgroundColor={Style.colors.primary} minWidth={Style.unset} on:click={onClose}>{translationContext.close}</Button>
                <Button backgroundColor={Style.colors.primary} minWidth={Style.unset} on:click={() => window.open(savedPresentationUrl, '_blank').focus()}>{translationContext.open}</Button>
            {:else}
                <Button isDisabled={!isRequesterInfoValid} on:click={onSubmit}>{translationContext.saveAsNew}</Button>
            {/if}
        </Row>
    </svelte:fragment>
</Modal>

<style>
    :global(.pointer input) {
        cursor: pointer;
    }
    
    .pointer {
        width: 100%;
    }
</style>
