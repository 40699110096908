<script lang="ts">
    import Fuse from 'fuse.js'
    import {onDestroy, onMount} from "svelte";

    export let items: [];
    export let keys: string[];
    export let placeholder: string;
    export let filteredItems: [];

    const MINIMUM_SEARCH_CHARACTER_LENGTH: number = 1;

    function filter(items: [], filter: String) {
        if (filterValue.trim().length < MINIMUM_SEARCH_CHARACTER_LENGTH) {
            return items;
        }

        return fuzzySearch.search(filter).map(item => item.item);
    }

    function focusOnShortcut(e) {
        if (e.ctrlKey && e.code === 'KeyF') {
            if (filterInput == document.activeElement) {
                return true;
            } else {
                e.preventDefault();
                filterInput.focus();
            }
        }
    }

    onMount(() => window.addEventListener('keydown', focusOnShortcut));
    onDestroy(() => window.removeEventListener('keydown', focusOnShortcut));


    let filterInput: HTMLInputElement;
    let filterValue = "";

    $: fuzzySearch = new Fuse(items, {keys: keys, ignoreLocation: true, threshold: .2});
    $: filteredItems = filter(items, filterValue);
</script>
<div class="search-container">
    <input type="search" bind:this={filterInput} bind:value={filterValue} placeholder={placeholder}>
    <span class="mdi mdi-magnify floating-button-content"/>
</div>

<style>
    .search-container {
        height: 100%;
    }

    input[type=search] {
        line-height: 45px;
        font-size: 1.3rem;
        border: none;
        text-align: right;
    }

    input[type=search]:focus {
        outline: none;
    }

    input[type=search], .search-container span {
        color: #202020;
    }
</style>
