﻿<div>
    <slot/>
</div>

<style>
    div {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        margin: 0 calc(max(1rem, (100vw - 1500px) * .5));
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;
    }
</style>
