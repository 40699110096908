﻿<script lang="ts">
    import {Button, Row} from 'Components/UI';
    import Dialog from 'Pages/PublicationPage/Components/Dialog.svelte';
    import {getContext, onDestroy, onMount} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import {applicationContextKey} from 'PublicationDependencies';
    import CustomLinkContext from 'Pages/PublicationPage/Lib/CustomLinkContext';
    import NotificationService from 'Lib/Services/NotificationService';
    import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';
    import CustomLinkSearchBox from 'Pages/PublicationPage/Views/CustomLinks/CustomLinkSearchBox.svelte';
    import {CustomLink} from 'Generated/RestClient.g';
    import CustomLinkService from 'Pages/PublicationPage/Lib/Services/CustomLinkService';
    import translations from 'Assets/i18n';

    const customLinkNameMaxLength = 256;
    
    const translationContext = translations.pages.publication.customLinksEditor;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const notificationService = applicationContext.get(NotificationService);
    const {
        itemToEditObservable,
        newLinkObservable,
        editorErrorTextObservable
    } = applicationContext.get(CustomLinkContext);
    const customLinkService = applicationContext.get(CustomLinkService);
    const {scenarioListObservable} = applicationContext.get(PublicationListContext);

    let linkCreationMode = false;
    let item: CustomLink = new CustomLink();
    let nameInput: HTMLInputElement;
    let form: HTMLFormElement;
    onMount(() => {
        linkCreationMode = $newLinkObservable;
        if (linkCreationMode) nameInput?.focus();

        item = new CustomLink($itemToEditObservable);
    });
    onDestroy(() => {
        editorErrorTextObservable.set(undefined);
    });

    function cancel() {
        itemToEditObservable.set(undefined);
        newLinkObservable.set(false);
    }

    async function save() {
        if (!form.checkValidity()) {
            editorErrorTextObservable.set(translationContext.errors.matchRequiredFormat);
            return;
        }
        
        if($itemToEditObservable?.name != item.name && customLinkService.checkNameAlreadyExists(item.name)){
            editorErrorTextObservable.set(translationContext.errors.nameAlreadyExists);
            return;
        }

        if (linkCreationMode) await create();
        else await edit();

        itemToEditObservable.set(undefined);
        newLinkObservable.set(false);
    }

    async function create() {
        item.createdAt = new Date(Date.now());
        try {
            await customLinkService.createNewCustomLink(item);
        } catch (e) {
            console.error(e);
            editorErrorTextObservable.set(translationContext.errors.errorCreatingCustomLink);
            return;
        }
        notificationService.info(translationContext.messages.created);
    }

    async function edit() {
        try {
            await customLinkService.editCustomLink(item);
        } catch (e) {
            console.error(e);
            notificationService.error(translationContext.errors.errorSavingCustomLink);
            return;
        }
        notificationService.info(translationContext.messages.saved);
    }
</script>

{#if item}
    <Dialog>
        <form on:submit|preventDefault={save} bind:this={form}>
            <label>
                {translationContext.inputs.nameLabel}<br>
                <input class="title"
                       bind:value={item.name}
                       placeholder={translationContext.inputs.namePlaceholder} bind:this={nameInput}
                       required
                       pattern="[A-Za-z0-9_\-]+"
                       maxlength={customLinkNameMaxLength}
                >
                <span>{translationContext.errors.matchRequiredFormat.format(customLinkNameMaxLength.toString())}</span>
            </label>
            {#if $editorErrorTextObservable}
                <span class="error">{$editorErrorTextObservable}</span>
            {/if}
            <label class="area">
                {translationContext.inputs.descriptionLabel}<br>
                <textarea bind:value={item.description} placeholder={translationContext.inputs.descriptionPlaceholder}/>
            </label>
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <label>
                {translationContext.inputs.connectedPresentationLabel}<br>
                <CustomLinkSearchBox scenarios={$scenarioListObservable}
                                     bind:selectedScenarioId={item.presentationId}/>
            </label>
            <Row>
                <div class="spacer"/>
                <Button>
                    {linkCreationMode ? translationContext.buttons.create : translationContext.buttons.save}
                </Button>
                <Button on:click={cancel}>
                    {translationContext.buttons.cancel}
                </Button>
            </Row>
        </form>
    </Dialog>
{/if}
<style>
    .spacer {
        flex-grow: 1;
    }

    .title {
        font-size: 1.3rem;
    }

    .area {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    textarea {
        flex-grow: 1;
        resize: none;
        font-family: var(--font-family);
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        height: 100%;
    }

    input {
        width: 100%;
    }

    label {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .2rem;
    }
    label > input + span {
        display: none;
    }
    label > input:invalid + span {
        display: block;
        color: red;
    }

    .error {
        color: red;
    }
</style>
