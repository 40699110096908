<script lang="ts">
    import translations from 'Assets/i18n';
    import type {Nullable} from 'Lib/Utils/Nullable';
    import type {ScenarioWithFiles} from 'Generated/RestClient.g';
    import tooltip from 'Lib/Utils/Tooltip';

    const translationContext = translations.pages.publication.presentationListView;
    
    export let item: Nullable<ScenarioWithFiles>
    
    $: scenario = item as ScenarioWithFiles;
</script>

{#if scenario.sharedWith}
    <span class="mdi mdi-content-duplicate" use:tooltip={translationContext.sharedWith.format(scenario.sharedWith)}/>
{/if}
