﻿<script lang="ts">
    import {Row} from 'Components/UI';
    import PopupMenu from 'Pages/PublicationPage/Components/PopupMenu.svelte';
    import {translations} from 'Assets/i18n/en-US';
    import ActionButton from 'Pages/PublicationPage/Components/ActionButton.svelte';
    import {getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import {applicationContextKey} from 'PublicationDependencies';
    import CustomLinkContext from 'Pages/PublicationPage/Lib/CustomLinkContext';
    import type {CustomLink} from 'Generated/RestClient.g';
    import CustomLinkService from 'Pages/PublicationPage/Lib/Services/CustomLinkService';
    import UserContext from 'Lib/UserContext';

    const translationContext = translations.pages.publication.customLinksListView;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const {itemToEditObservable, linkToDeleteObservable} = applicationContext.get(CustomLinkContext);
    const userContext = applicationContext.get(UserContext);
    const customLinkService = applicationContext.get(CustomLinkService);

    export let disabled = false;
    export let item: CustomLink;
    let shareMenuVisible = false;

    $: disabledOrNotOwned = disabled || item?.ownerId != userContext.currentUser?.id;

    function showPopup() {
        itemToEditObservable.set(item);
    }
</script>

<Row width="100%">
    <ActionButton
            tooltipContent={translationContext.actions.openScenario}
            on:click={()=>customLinkService.openCustomLink(item.id)}
            {disabled}
    >
        <span class="mdi mdi-open-in-new"/>
    </ActionButton>
    <!--Popup menu needs this div for proper positioning-->
    <div>
        <ActionButton
                tooltipContent={translationContext.actions.shareScenario}
                on:click={() => shareMenuVisible=true}
                {disabled}
        >
            <span class="mdi mdi-share-variant-outline"/>
        </ActionButton>
        {#if shareMenuVisible}
            <PopupMenu bind:visible={shareMenuVisible}>
                <ActionButton
                        tooltipContent={translationContext.actions.copyScenarioURI}
                        on:click={()=>customLinkService.copyCustomLink(item.id)}>
                    <span class="mdi mdi-link"/>
                </ActionButton>
                <ActionButton
                        tooltipContent={translationContext.actions.copyEmbedCode}
                        on:click={()=>customLinkService.copyCustomLinkEmbedCode(item.id)}>
                    <span class="mdi mdi-application-brackets-outline"/>
                </ActionButton>
            </PopupMenu>
        {/if}
    </div>
    <ActionButton
            tooltipContent={translationContext.actions.edit}
            on:click={showPopup}
            {disabled}
    >
        <span class="mdi mdi-file-edit-outline"/>
    </ActionButton>
    <ActionButton
            tooltipContent={translationContext.actions.delete}
            on:click={() => linkToDeleteObservable.set(item)}
            disabled={disabledOrNotOwned}
    >
        <span class="mdi mdi-delete-outline"/>
    </ActionButton>
</Row>

<style>
    .mdi {
        font-size: 30px;
        color: #0078d7;
    }
</style>
