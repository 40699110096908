﻿<script lang="ts">
    import {Button, Modal, Row} from 'Components/UI';
    import translations from 'Assets/i18n';
    import {applicationContextKey} from 'PublicationDependencies';
    import PublicationListService from 'Pages/PublicationPage/Lib/Services/PublicationListService';
    import * as Style from 'Components/Style';
    import type {Nullable} from 'Lib/Utils/Nullable';
    import {getContext} from 'svelte';
    import type DependencyContainer from 'Lib/DependencyContainer';
    import CustomLinkService from 'Pages/PublicationPage/Lib/Services/CustomLinkService';
    import type {Scenario} from 'Generated/RestClient.g';

    const translationContext = translations.pages.publication.presentationDeletionDialog;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const publicationListService = applicationContext.get(PublicationListService);
    const customLinkService = applicationContext.get(CustomLinkService);

    export let scenario: Scenario | undefined = undefined;

    async function deleteScenarioAsync(): Promise<void> {
        if (scenario === undefined) {
            console.error('No scenario id was found.');
            return;
        }

        await publicationListService.deleteScenarioAsync(scenario.id);
        scenario = undefined;
    }

    $: connectedLinks = customLinkService.getConnectedLinks(scenario?.id);
</script>

<Modal
        isShown={scenario !== undefined}
        title={translationContext.title}>
    <svelte:fragment slot="content">
        <p>{translationContext.description.format(scenario?.name ?? "")}</p>
        {#if connectedLinks && connectedLinks.length > 0}
            <p class="warning">
                {#if connectedLinks.length === 1}
                    {translationContext.connectedLinkWarning}
                {:else}
                    {translationContext.connectedLinksWarning}
                {/if}
            </p>
            <ul>
                {#each connectedLinks as link}
                    <li>{link.name}</li>
                {/each}
            </ul>
        {/if}
    </svelte:fragment>
    <svelte:fragment slot="buttons">
        <Row horizontalAlign="end">
            <Button
                    backgroundColor={Style.colors.negative}
                    minWidth={Style.unset}
                    on:click={deleteScenarioAsync}>
                {translationContext.confirmAction}
            </Button>
            <Button
                    minWidth={Style.unset}
                    on:click={() => (scenario = undefined)}>
                {translationContext.cancelAction}
            </Button>
        </Row>
    </svelte:fragment>
</Modal>

<style>
    .warning {
        font-weight: bold;
    }

    ul {
        list-style: circle inside;
    }
</style>
