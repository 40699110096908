﻿<script lang="ts">
</script>
<div class="overlay">
    <div class="dialog">
        <slot/>
    </div>
</div>

<style>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dialog {
        position: fixed;
        top: calc(1wh - 60wh * .5);
        left: calc(1wv - (min(100wv - 2rem, 800px) * .5));
        display: flex;
        flex-direction: column;
        max-width: 800px;
        width: calc(100% - 2rem);
        background-color: white;
        padding: 2rem;
        height: 40%;
        gap: 1rem;
    }
</style>
